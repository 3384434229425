<template>
  <DefaultLayout>
     <!-- Masthead -->
     <header class="masthead">
      <div class="container">       
      </div>
    </header>
    <h1>Welcome to the Careers Page - more info coming soon</h1>
    <div>
    <!-- Other sections or components above if needed -->

    <!-- Careers Page Section -->
    <section id="careers" class="py-5">
      <b-container fluid>
        <b-row>
          <b-col>
            <!-- Page Header with Font Awesome Icon -->
            <h1 class="text-center"><i class="fas fa-users"></i> Join Our Team</h1>
          </b-col>
        </b-row>

        <!-- Job Openings Section -->
        <b-row>
          <b-col md="8">
            <h2><i class="fas fa-briefcase"></i> Current Job Openings</h2>
            <!-- Job List -->
            <b-list-group>
              <b-list-group-item v-for="job in jobs" :key="job.id">
                <b-row>
                  <b-col sm="9">
                    <h4>{{ job.title }}</h4>
                    <p>{{ job.description }}</p>
                  </b-col>
                  <b-col sm="3" class="text-right">
                    <b-button variant="primary">Apply <i class="fas fa-arrow-right"></i></b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          
          <!-- Sidebar with Additional Information -->
          <b-col md="4">
            <h3><i class="fas fa-info-circle"></i> Why Join Us?</h3>
            <ul>
              <li><i class="fas fa-check-circle"></i> Exciting projects and challenges</li>
              <li><i class="fas fa-check-circle"></i> Great company culture</li>
              <li><i class="fas fa-check-circle"></i> Opportunities for growth</li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- Other sections or components below if needed -->
  </div>
    
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; Plurall AI 2024</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/plurall-ai/" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a class="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
        </footer>    
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/masthead.css';
export default {
  name: 'CareersView',
  components: {
    DefaultLayout,
  },
  data() {
  return {
    jobs: [
      { id: 1, title: 'Placeholder 1', description: 'Description 1' },
      { id: 2, title: 'Placeholder 2', description: 'Description 2' },
      { id: 3, title: 'Placeholder 3', description: 'Description 3' }
      // Add more job listings as needed
    ]
  };
}
};
</script>

<style scoped>
/* General styles */
.spacer {
  height: 20px; /* Adjust spacing height as needed */
}
/* Custom CSS styles can be added here */
h1 {
  color: #333;
}

h2 {
  color: #555;
}

h3 {
  color: #007bff;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  margin-bottom: 10px;
}

.b-list-group-item {
  margin-bottom: 20px;
}

/* Adjust Font Awesome icons size and color */
.fa {
  margin-right: 5px; /* Add space between icon and text */
  font-size: 1.2rem; /* Adjust icon size */
  color: #007bff; /* Adjust icon color */
}
</style>
