<template>
  <DefaultLayout>
     <!-- Masthead -->
     <header class="masthead">
      <div class="container">       
      </div>
    </header>
    <h1>Welcome to the Use Cases Page - more info coming soon</h1>
  

  <!-- Spacer below navigation bar -->
  <div class="spacer"></div>

  <!-- First section: Two images side by side -->
  <div class="image-section">
    <div class="image-row">
      <img src="../assets/Component 1.png" alt="Image 1" class="side-by-side-image">
      <!-- Add Image 2 here if needed -->
    </div>
  </div>

  <!-- Spacer between sections -->
  <div class="spacer"></div>

  <!-- Second section: Three images stacked -->
  <div class="image-section">
    <div class="image-stack">
      <!-- Add Image 3 here if needed -->
      <!--img src="../assets/Group 1000001771.png" alt="Image 3" class="stacked-image">
      <img src="../assets/Pain Points1.jpg" alt="Image 4" class="stacked-image">-->
      <img src="../assets/Group 1000001767.png" alt="Image 5" class="stacked-image">
    </div>
  </div>

  
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; Plurall AI 2024</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/plurall-ai/" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a class="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
        </footer>    
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/masthead.css';
export default {
  name: 'UseCases',
  components: {
    DefaultLayout,
  },
};
</script>

<style scoped>
/* General styles */
.spacer {
  height: 20px; /* Adjust spacing height as needed */
}

.image-section {
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
  margin: 0 auto; /* Center the content */
}

.image-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Space between rows */
}

.side-by-side-image {
  max-width: 100%; /* Ensure images don't overflow on smaller screens */
  height: auto;
  margin: 0 10px; /* Space between images */
}

.image-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stacked-image {
  width: 100%; /* Make images take full width of their container */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space between stacked images */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .image-row {
    flex-direction: column; /* Stack images vertically on smaller screens */
  }
}
</style>
