import { createApp } from 'vue' // Import createApp for Vue 3
import App from './App.vue'  // Import main App component
import router from './router' // Import the router instance

// Import Bootstrap and BootstrapVueNext CSS files
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Import BootstrapVueNext
import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';


const app = createApp(App)  // Create Vue app instance

app.use(router)  // Use the router instance
app.use(BootstrapVueNext)  // Use BootstrapVueNext

app.mount('#app');  // Mount the app to the DOM element with the ID 'app'


// import { createApp } from 'vue' // For Importing  createApp for Vue js 3
// import App from './App.vue'  // Importing mai App component
// import router from './router' // Importing the router instance
// //import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap's CSS
// //import 'bootstrap'; // Import Bootstrap's JS

// createApp(App)  //Creating Vue app instance
//     .use(router)  // Using the router instance 
//     .mount('#app');  // this monts the app to the DOM element which has the ID 'app'
