<template>
  <DefaultLayout>
    <!-- Masthead -->
    <header class="masthead">
      <div class="container">       
      </div>
    </header>
    
    <!-- Contact-->

    <section class="page-section" id="contact">
            <div class="container">
                <div class="spacer"></div>
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Contact Us </h2>
                    <h3 class="section-subheading text-muted">Let us know how we can help.</h3>
                </div>
                <b-container>
                  <b-row>
                    <b-col cols="md:4">
                      <ul class="list-unstyled">
                        <li>
                          <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-1x custom-primary" ></i>
                            <i class="fas fa-phone fa-stack-1x fa-inverse" color:blue_icon ></i>
                          </span>
                          <span class="text-light hover:text-white">+1 646-698-8167</span>
                        </li>
                        <li>
                          <span class="fa-stack fa-4x">
                            <i class="fas fa-circle fa-stack-1x custom-primary"></i>
                            <i class="fas fa-envelope fa-stack-1x fa-inverse" color:blue_icon ></i>
                          </span>
                          <a href="mailto:info@plurall.ai" class="text-light hover:text-white">info@plurall.ai</a>
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </b-container>
                <!-- * * * * * * * * * * * * * * *-->
                <!-- * * SB Forms Contact Form * *-->
                <!-- * * * * * * * * * * * * * * *-->
                <!-- This form is pre-integrated with SB Forms.-->
                <!-- To make this form functional, sign up at-->
                <!-- https://startbootstrap.com/solution/contact-forms-->
                <!-- to get an API token!-->
                <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                                <!-- Name input-->
                                <input class="form-control" id="name" type="text" placeholder="Your Name *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                            </div>
                            <div class="form-group">
                                <!-- Email address input-->
                                <input class="form-control" id="email" type="email" placeholder="Your Email *" data-sb-validations="required,email" />
                                <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                            </div>
                            <div class="form-group">
                                <!-- Location input-->
                                <input class="form-control" id="Location" type="text" placeholder="Your Location *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="Location:required">A Location is required.</div>
                            </div>
                            <div class="form-group mb-md-0">
                                <!-- Phone number input-->
                                <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                                <!-- Message input-->
                                <textarea class="form-control" id="message" placeholder="Your Message *" data-sb-validations="required"></textarea>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                            </div>
                        </div>
                    </div>
                    <!-- Submit success message-->
                    <!---->
                    <!-- This is what your users will see when the form-->
                    <!-- has successfully submitted-->
                    <div class="d-none" id="submitSuccessMessage">
                        <div class="text-center text-white mb-3">
                            <div class="fw-bolder">Form submission successful!</div>
                            To activate this form, sign up at
                            <br />
                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                        </div>
                    </div>
                    <!-- Submit error message-->
                    <!---->
                    <!-- This is what your users will see when there is-->
                    <!-- an error submitting the form-->
                    <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                    <!-- Submit Button-->
                    <div class="text-center"><button class="btn btn-primary btn-xl text-uppercase disabled" id="submitButton" type="submit">Send Message</button></div>
                </form>
            </div>
        </section>    

    
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; Plurall AI 2024</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/plurall-ai/" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a class="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
        </footer>    
  </DefaultLayout>

  <!-- Spacer below navigation bar -->
  
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/masthead.css';

export default {
  name: 'ContactsView',
  components: {
    DefaultLayout,
  },
};
</script>

<style scoped>
/* General styles */
.spacer {
  height: 20px; /* Adjust spacing height as needed */
}
.blue_icon {
  color: aqua; /* Adjust spacing height as needed */
}
.image-section {
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
  margin: 0 auto; /* Center the content */
}

h1 {
  margin-top: 120px; /* Adjust to provide space for the image */
}

.side-by-side-image {
  max-width: 100%; /* Ensure images don't overflow on smaller screens */
  height: auto;
  margin: 0 10px; /* Space between images */
}


.top-left-image img {

  width: 200px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
}

.top-left-image a {
  display: block;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-brand {
  width: auto;
  max-height: 50px; /* Adjust as needed */
}
/* Media queries for responsive design */
@media (max-width: 768px) {
  .side-by-side-image {
    flex-direction: column; /* Stack images vertically on smaller screens */
  }
}
</style>