<template>
  <DefaultLayout>
     <!-- Masthead -->
     <header class="masthead">
      <div class="container">       
      </div>
    </header>
    <h1>Welcome to the Plurall Papers Page - more info coming soon</h1>
    <b-container fluid class="research-papers-page">
    <b-row>
      <!-- Sidebar for navigation -->
      <b-col md="3" class="sidebar bg-light">
        <b-list-group>
          <b-list-group-item href="#!" v-b-toggle.papers-accordion>
            Papers
          </b-list-group-item>
          <b-list-group-item href="#!" v-b-toggle.resources-accordion>
            Resources
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!-- Content Pane for displaying papers and resources -->
      <b-col md="9" class="content-pane">
        <b-collapse id="papers-accordion" visible>
          <b-row>
            <b-col v-for="paper in papers" :key="paper.id" md="4" class="paper-item">
              <b-card>
                <b-card-img :src="paper.thumbnail" alt="Thumbnail"></b-card-img>
                <b-card-body>
                  <h5 class="card-title">{{ paper.name }}</h5>
                  <b-link :href="paper.link" class="card-link" target="_blank">Read Paper</b-link>
                  <p class="card-text">{{ paper.summary }}</p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-collapse>
        <b-collapse id="resources-accordion">
          <!-- Add resources content here -->
        </b-collapse>
      </b-col>
    </b-row>
  </b-container>
    
    
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; Plurall AI 2024</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/plurall-ai/" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a class="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
        </footer>    
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/masthead.css';

export default {
  name: 'PapersView',
  components: {
    DefaultLayout,
  },
  data() {
    return {
      papers: [
        {
          id: 1,
          name: 'Research Paper 1',
          link: 'https://example.com/paper1',
          thumbnail: 'https://via.placeholder.com/150',
          summary: 'This is a summary of research paper 1.'
        },
        {
          id: 2,
          name: 'Research Paper 2',
          link: 'https://example.com/paper2',
          thumbnail: 'https://via.placeholder.com/150',
          summary: 'This is a summary of research paper 2.'
        },
        {
          id: 3,
          name: 'Research Paper 3',
          link: 'https://example.com/paper3',
          thumbnail: 'https://via.placeholder.com/150',
          summary: 'This is a summary of research paper 3.'
        }
        // Add more papers as needed
      ]
    };
  }
};
</script>

<style scoped>
/* General styles */
.spacer {
  height: 20px; /* Adjust spacing height as needed */
}

.image-section {
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
  margin: 0 auto; /* Center the content */
}

.research-papers-page {
  padding: 20px;
}

.sidebar {
  padding: 20px;
}

.content-pane {
  padding: 20px;
}

.paper-item {
  margin-bottom: 20px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.card-link {
  display: block;
  margin-bottom: 10px;
}
/* Media queries for responsive design */
@media (max-width: 768px) {
  .image-row {
    flex-direction: column; /* Stack images vertically on smaller screens */
  }
}
</style>
