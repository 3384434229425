<template>
  <DefaultLayout>
    <!-- Masthead -->
    <!--header class="masthead">
      <div class="container">
                
      </div>
    </header-->
    
    <!-- Services -->
    <section class="hero-heading-center">
      <div class="container">
        <div class="row hero-wrapper">
          <div class="col-md-6 col-sm-12 hero-split d-flex align-items-center justify-content-center">
            <div>
              <div class="div-block-3 mb-3">
                <img src="../assets/image 6.png" class="img-fluid" alt="Image 6">
              </div>
              <div class="div-block-2 text-center">
                <div class="anti-deepfake-anti-fraud-ai-detection mb-3">
                  <img class="img-fluid img-brand d-block mx-auto" src="../assets/anti-deepfake & anti-fraud ai detection.png" alt="..." aria-label="Anti_logo" /></div>
                <a href="https://forms.gle/ECNrfrJiMEbwpVYC9" class="btn btn-primary">Join Waitlist</a>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
            <img src="../assets/GaussMass.png" class="img-fluid" alt="Gauss Mass" srcset="../assets/GaussMass-p-500.png 500w, ../assets/GaussMass-p-800.png 800w, ../assets/GaussMass.png 884w">
          </div>
        </div>
      </div>
    </section>
 


    <!-- Clients-->
        <div class="py-5">
            <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Partnership & SUpport</h2>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/Nvidia_logo.svg" alt="..." aria-label="Microsoft Logo" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/google.png" alt="..." aria-label="Google Logo" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/microsoft.png" alt="..." aria-label="Facebook Logo" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="#!"><img class="img-fluid img-brand d-block mx-auto" src="../assets/img/logos/HBKU_logo.svg" alt="..." aria-label="IBM Logo" /></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Pain Points Section -->
        <section class="text-center my-5">
          <div class="container d-flex justify-content-center">
            <div class="box p-4">
              <h2>Tell Us Your Pain Points</h2>
              <p>Spend 5 minutes to fill out this user survey!</p>
              <a class="btn btn-primary" href="https://www.linkedin.com/company/plurall-ai/">Share Feedback</a>
            </div>
            </div>
        </section>

         <!-- Footer -->
  <footer class="bg-black py-4">
    <div class="container text-center">
      <div class="d-flex justify-content-between">
        <a href="#" class="d-block mb-3">
          <img src="../assets/logo.jpg" class="img-fluid-logo" alt="imagelogo">
          <p>Empowering trust in Technology</p>
        </a>
        <a class="btn btn-custom-waitlist mb-3 mr-2" href="https://forms.gle/ECNrfrJiMEbwpVYC9">Join Waitlist</a></div>
      <div class="footer-divider-two"></div>
      <div class="d-flex justify-content-between">
        <p>© 2024 Plurall AI. All rights reserved.</p>
        <div>
          <a href="https://www.linkedin.com/company/plurall-ai/" class="mr-2 m-2"><i class="fab fa-2x fa-linkedin"></i></a>
          <a href="https://www.instagram.com/plurallai/" class="mr-2 m-2"><i class="fab fa-2x fa-instagram"></i></a>
          <a href="https://x.com/plurall_ai" class="mr-2 m-2"><i class="fab fa-2x fa-twitter"></i></a>
        </div>
      </div>
    </div>
  </footer>
      

  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/masthead.css';


export default {
  name: 'HomeView',
  components: {
    DefaultLayout,
  },
};
</script>

<style scoped>

/* Custom color class */
.custom-primary {
  color: #c389e7; /* Replace with your desired color */
}

/* Ensure images are responsive */
.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Utility styles for the buttons */
.btn-xl {
  padding: 1rem 2rem;
  font-size: 1.25rem;
}
.spacer {
  height: 20px; /* Adjust spacing height as needed */
}


@media (max-width: 990px) {
 
  .image-stack {
    flex-direction: column; /* Stack images vertically on smaller screens */
  }

  .stacked-image {
    width: 80%; /* Adjust stacked image width on smaller screens */
  }
}
.box {
  height:auto;
  width: 500px;
  background-color: #1b1b1b; /* Light grey background */
  border: 1px solid #dee2e6; /* Slightly darker grey border */
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}
.img-fluid-logo{
  height:auto;
  width: 400px;
  padding-left: 10px 20px 20px; 

}
.btn-custom-waitlist {
  background-color: #dbdbdc;; /* Same as the background color */
  border: 1px white; /* Remove the border */
  color: inherit; /* Inherit the text color */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin between buttons */
  font-size: inherit; /* Inherit the font size */
  height:60px;
  width: auto;

}

.btn-custom-waitlist:hover,
.btn-custom-waitlist:focus,
.btn-custom-waitlist:active {
  background-color: transparent; /* Maintain the background color on hover/focus/active */
  border: 1px white; /* Ensure the border remains removed */
  color: white; /* Maintain the text color */
  outline: white; /* Remove the outline on focus */
  box-shadow: white; /* Remove any box-shadow on focus */
}
</style>
