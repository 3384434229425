<template>
  <div>
     <!-- Include the navigation bar component -->
    <NavBar />
    <slot>
      <!-- Slot for child content, will be replaced by the content of the specific view -->
    
    </slot>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'DefaultLayout',
  components: {
    NavBar,
  },
};
</script>

<style>
/* Add any additional layout styling here */
</style>