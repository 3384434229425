import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';
import UseCases from './views/UseCases.vue';
import BlogResources from './views/BlogResources.vue';
import PapersView from './views/PapersView.vue';
import CareersView from './views/CareersView.vue';
import ContactsView from './views/ContactsView.vue';
import FeedbackView from './views/FeedbackView.vue';
import WaitlistView from './views/WaitlistView.vue';

const routes = [
  { path: '/', component: HomeView },
  { path: '/UseCases', component: UseCases },
  { path: '/BlogResources', component: BlogResources },
  { path: '/PapersView', component: PapersView },
  { path: '/CareersView', component: CareersView },
  { path: '/ContactsView', component: ContactsView },
  { path: '/FeedbackView', component: FeedbackView },
  { path: '/WaitlistView', component: WaitlistView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;