<template>

  <header class="header">
    <!-- Navigation bar with buttons for navigation -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-black navbar-bordered" style="border-color: #fff" >
      <div class="container-fluid">
        <!-- Logo -->
        <a class="navbar-brand" href="#">
          <img src="@/assets/logo.jpg" alt="Logo" class="logo" />
        </a>

        <!-- Toggler/collapsible Button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a href="https://plurall.tech/deepfake/" class="btn btn-custom-about">About</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-custom-contact mb-3 mr-2" href="https://www.linkedin.com/company/plurall-ai/">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-custom-feedback mb-3 mr-2" href="https://www.linkedin.com/company/plurall-ai/">Share Feedback</a>
            </li>
            <li class="nav-item">
              <a class="btn btn-custom-waitlist mb-3 mr-2" href="https://forms.gle/ECNrfrJiMEbwpVYC9">Join Waitlist</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'NavBar',

  data() {
    return {
      showDropdown: false,
    };
  },

  methods: {
    navigate(page) {
      this.$router.push(page); // Using Vue router to navigate
    },
  },
};
</script>

<style scoped>
/* Header styles */
.header {
  background-color: black;
}

/* Logo styles */
.logo {
  height: 50px;
  width: auto;
}

/* Navbar styles */
.navbar {
  padding: 10px 20px;
}

/* Dropdown styles */
.dropdown-menu {
  background-color: #333;
  border: 1px solid #ccc;
}

/* Dropdown item styles */
.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  background-color: #555;
}

/* General button styles */
.nav-link {
  color: white !important;
  margin-left: 10px;
}

.nav-link:hover {
  color: #ff4500 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
.navbar-bordered {
  border: transparent; /* Adjust the border thickness as needed */
  border-radius: 10px;
}

.btn-custom-about {
  background-color: transparent; /* Same as the background color */
  border: none; /* Remove the border */
  color: white; /* Inherit the text color */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin between buttons */
  font-size: inherit; /* Inherit the font size */
}

.btn-custom-about:hover,
.btn-custom-about:focus,
.btn-custom-about:active {
  background-color: transparent; /* Maintain the background color on hover/focus/active */
  border: none; /* Ensure the border remains removed */
  color: white; /* Maintain the text color */
  outline: white; /* Remove the outline on focus */
  box-shadow: white; /* Remove any box-shadow on focus */
}

.btn-custom-contact {
  background-color: transparent; /* Same as the background color */
  border: none; /* Remove the border */
  color: white; /* Inherit the text color */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin between buttons */
  font-size: inherit; /* Inherit the font size */
}

.btn-custom-contact:hover,
.btn-custom-contact:focus,
.btn-custom-contact:active {
  background-color: transparent; /* Maintain the background color on hover/focus/active */
  border: none; /* Ensure the border remains removed */
  color: white; /* Maintain the text color */
  outline: white; /* Remove the outline on focus */
  box-shadow: white; /* Remove any box-shadow on focus */
}

.btn-custom-feedback {
  background-color: transparent; /* Same as the background color */
  border: 2px white; /* Remove the border */
  color: white; /* Inherit the text color */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin between buttons */
  font-size: inherit; /* Inherit the font size */
}

.btn-custom-feedback:hover,
.btn-custom-feedback:focus,
.btn-custom-feedback:active {
  background-color: transparent; /* Maintain the background color on hover/focus/active */
  border: 1px white; /* Ensure the border remains removed */
  color: white; /* Maintain the text color */
  outline: white; /* Remove the outline on focus */
  box-shadow: white; /* Remove any box-shadow on focus */
}

.btn-custom-waitlist {
  background-color: #dbdbdc;; /* Same as the background color */
  border: 1px white; /* Remove the border */
  color: inherit; /* Inherit the text color */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin between buttons */
  font-size: inherit; /* Inherit the font size */

}

.btn-custom-waitlist:hover,
.btn-custom-waitlist:focus,
.btn-custom-waitlist:active {
  background-color: transparent; /* Maintain the background color on hover/focus/active */
  border: 1px white; /* Ensure the border remains removed */
  color: white; /* Maintain the text color */
  outline: white; /* Remove the outline on focus */
  box-shadow: white; /* Remove any box-shadow on focus */
}



</style>
