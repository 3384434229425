<template>
  <DefaultLayout>
     <!-- Masthead -->
     <header class="masthead">
      <div class="container">       
      </div>
    </header>
    
    <section class="feedback-section">
        <div class="container2">
          <h1>Your feedback is valuable to us. Please share your thoughts! </h1>
            <div class="row align-items-center">
                <div class="col-md-3 logo-column">
                    <img src="../assets/image 6.png" alt="Logo" class="Plurall_logo">
                    <img src="../assets/anti-deepfake & anti-fraud ai detection.png" alt="Logo" class="Plurall_logo">
                </div>
                <div class="spacer"></div>
                <div class="col-md-9 form-column">
                    <form id="feedbackForm">
                        <div class="row align-items-stretch mb-5">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- Name input-->
                                    <input class="form-control" id="name" type="text" placeholder="Your Name *" required />
                                    <div class="invalid-feedback">A name is required.</div>
                                </div>
                                <div class="form-group">
                                    <!-- Email address input-->
                                    <input class="form-control" id="email" type="email" placeholder="Your Email *" required />
                                    <div class="invalid-feedback">An email is required.</div>
                                    <div class="invalid-feedback">Email is not valid.</div>
                                </div>
                                <div class="form-group">
                                    <!-- Phone number input-->
                                    <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" required />
                                    <div class="invalid-feedback">A phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group form-group-textarea mb-md-0">
                                    <!-- Message input-->
                                    <textarea class="form-control" id="message" placeholder="Please Share your Feedback *" required></textarea>
                                    <div class="invalid-feedback">Let us know how we can improve.</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary btn-xl text-uppercase" id="submitButton" type="submit">Share</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
        <!-- Footer-->
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start">Copyright &copy; Plurall AI 2024</div>
                    <div class="col-lg-4 my-3 my-lg-0">
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="#!" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/plurall-ai/" aria-label="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a class="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
        </footer>    
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/components/DefaultLayout.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/masthead.css';

export default {
  name: 'FeedbackView',
  components: {
    DefaultLayout,
  },
};
</script>

<style scoped>
/* General styles */

.spacer {
  height: 20px;
}


/* Media queries for responsive design */
@media (max-width: 768px) {
  .side-by-side-image {
    max-width: 100%;
  }
}
.h1 {
  color: rgb(247, 252, 252);
 
}
@media (max-width: 480px) {
  .image-row {
    flex-direction: column;
  }
}
.feedback-section {
    padding: 60px 0;
    /*background-image: url('path-to-your-background-image.jpg');  Change this to your image path */
    background-size: cover;
    background-position: center;
    position: relative;
    background-color: #3f3d3d;
}

.feedback-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Add a dark overlay */
    z-index: 1;
}

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2; /* Ensure the content is on top of the overlay */
}
.container2 {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2; /* Ensure the content is on top of the overlay */
}
.row {
    display: flex;
    align-items: center;
}

.Plurall_logo-column {
    text-align: center;
    padding: 20px;
}

.Plurall_logo {
    max-width: 75%;
    height: auto;
}

.form-column {
    padding: 20px;
    background-color: #0e0c0c;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.invalid-feedback {
    display: none;
    color: #dc3545;
    font-size: 0.875em;
}

.form-control:invalid + .invalid-feedback {
    display: block;
}

.text-center {
    text-align: center;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-primary {
    background-color: #007bff;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #0056b3;
}
.btn-social {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .logo-column,
    .form-column {
        width: 100%;
    }
}
</style>
